.FormInput {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    text-align: left;
    width: 350px;
}

.FormInput input {
    border: 2px solid gray;
    border-radius: 5px;
}

.FormInput span {
    display: none;
}

.FormInput input.focused:invalid ~ span  {
    display: block;
}

.FormInput label {
    font-size: 12pt;
    color: gray;
    padding-bottom: 5px;
}

.FormInput span {
    font-size: 12pt;
    color: red;
}
