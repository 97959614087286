.Login > hr {
    border: none;
    background-color: gray;
    height: 2px;
    margin: 30px 10px;
}

.Login .links {
    font-size: 12pt;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
}

.Login .links a {
    color: lightgray;
}

.Login .links a:visited {
    color: lightgray;
}
