.Form {
    background: white;
    color: black;
    padding: 0px 30px;
    border-radius: 20px;
}

.Form button {
    border: none;
    background-color: slategray;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: medium;
}

.Form h1 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: xx-large;
}

.Form > .error {
    padding-bottom: 10px;
}

.Form .error .text {
    color: darkred;
    background-color: lightpink;
    border-radius: 5px;
    font-size: large;
}
